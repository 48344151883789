<template>
  <div
    class="textSuspendedOnPicture-box px-textSuspendedOnPicture-box"
    @click="clickItem('box')"
    :class="`edit_${Xindex}_box`"
  >
    <img
      class="image"
      :src="configs.image"
      :alt="configs.alt"
    />
    <div class="su-box">
      <h1
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      ></h1>
      <h2
        class="sub-title"
        v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
      ></h2>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          image: '',
          alt: '',
          title: '',
          subTitle: '',
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false,
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.textSuspendedOnPicture-box {
  width: 100%;
  padding-top: 70px;
  display: inline-block;
  .image {
    height: calc(100vw / 5.48);
    width: 100%;
    min-height: calc(100vw / 5.48);
    background: #efefef;
    object-fit: cover;
    vertical-align: middle;
  }
  .su-box {
    width: calc(100vw / 1.5);
    position: relative;
    padding: 40px 100px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: -54px;
    background: #ffffff;
    box-shadow: 4px 4px 50px 0px rgba(0, 0, 0, 0.04),
      -4px -4px 50px 0px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    margin-bottom: 70px;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #101010;
      text-align: center;
    }
    .sub-title {
      margin-top: 10px;
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
    }
  }
}

@media screen and(max-width:1000px) {
  .px-textSuspendedOnPicture-box {
    .su-box {
      margin-top: -10px;
      padding: 20px 30px;
      width: calc(100vw - 132px);
      margin-bottom: 15px;
      .title {
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .sub-title {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}

@media screen and(max-width:500px) {
  .px-textSuspendedOnPicture-box {
    padding-top: 15px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.04),
      -3px -3px 10px 0px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    .image {
      height: calc(100vw / 5.51);
    }
    .su-box {
      margin-top: -10px;
      padding: 10px 12px;
      width: calc(100vw - 48px);
      margin-bottom: 15px;
      .title {
        font-size: 15px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .sub-title {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
